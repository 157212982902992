import Lottie from "react-lottie";
import "./App.css";
import minesLottie from "./lotties/mine.json";

function App() {
  const mineAnimationOption = {
    loop: true,
    autoplay: true,
    animationData: minesLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="App">
      <img alt="mines" src="/img/bg.png" className="img" />
      <Lottie options={mineAnimationOption} height={"125px"} width={"125px"} />
    </div>
  );
}

export default App;
